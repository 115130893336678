@keyframes mymove {
  from {
    transform: translateX(-20px);
  }
  to {
    transform: translateX(-40px);
  }
}

@keyframes chip {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.3);
    opacity: 1;
  }
}
